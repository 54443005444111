import apiConf from "./apiConfiguration";
import net from "./net";

const settingsApi = {
    get() {
        return net.get(apiConf.endpoint("/settings")).then((response) => response);
    },
    post(data) {
        return net.post(apiConf.endpoint("/settings"), data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then((response) => response);
    },
};

export default settingsApi;