import { applyMiddleware, combineReducers } from "redux";
import { configureStore } from '@reduxjs/toolkit';
import OrdersReducer from "./reducers/ordersReducer";
import SettingsReducer from "./reducers/settingsReducer";
import InfoReducer from "./reducers/infoReducer";
import UsersReducer from "./reducers/usersReducer";
import StatsReducer from "./reducers/statsReducer";
import ProductsReducer from "./reducers/productsReducer";
import ProductDetailsReducer from "./reducers/productDetailsReducer";
import HeaderReducer from "./reducers/headerReducer";
import CategoriesReducer from "./reducers/categoriesReducer";
import AuthReducer from "./reducers/authReducer";
import { reducer as formReducer } from "redux-form";

const thunkMiddleware = require('redux-thunk').default;

const reducers = combineReducers({
    orders: OrdersReducer,
    settings: SettingsReducer,
    info: InfoReducer,
    users: UsersReducer,
    stats: StatsReducer,
    products: ProductsReducer,
    categories: CategoriesReducer,
    productDetails: ProductDetailsReducer,
    header: HeaderReducer,
    form: formReducer,
    auth: AuthReducer
});
//const store = createStore(reducers,applyMiddleware(thunkMiddleware));

const store = configureStore({
    reducer: reducers,
    middleware: [thunkMiddleware]
})

export default store;