import styles from "./users.component.less";
import PageContent from "../../pageContent/pageContent";
import Caption from "../../caption/caption";
import helper from "../../../helpers/dateHelper";
import Clipboard from "../clipboard/clipboard";
export default function Users({ users }) {
    if (users == undefined || users.length == 0) return;
    let usersParsed = users.map(x => <UserLine key={x.id} user={x} />);

    return (
        <PageContent>
            <Caption title={`Пользователи - кол-во: ${users.length}`} />
            {usersParsed}
        </PageContent>
    );
}


function UserLine(props) {
    return (
        <div className={styles.userLine}>
            <div>
                Имя и фамилия: {props.user.fullname}
            </div>
            <div>
                Телефон: <Clipboard>
                    +{props.user.phonenumber}
                </Clipboard>
            </div>
            <div>
                Дата регистрации: {helper.toLocaleDate(props.user.regDate)}
            </div>
        </div>
    );
}