import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Info from "./info";
import { compose } from "redux";
import withRouter from "../../withRouter/withRouter";
import { ThunkCretorGetInfo, ThunkCretorSaveInfo } from "../../../context/reducers/infoReducer";
import PrivateRoute from "../private/privateRoute";


function InfoContainer(props) {
    let saveFormData = function ({ about }) {
        props.save({ about });
    }
    if (!props.info)
        props.get();
    else
        return <Info about={props.info.about} onSubmit={saveFormData} />
}


const mapStateToProps = (state, ownProps) => {
    return {
        info: state.info.data
    }
}

const mapDispatchToProps = (dispatch) => ({
    get: () => {
        dispatch(ThunkCretorGetInfo());
    },
    save: (data) => {
        dispatch(ThunkCretorSaveInfo(data));
    }
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    PrivateRoute
)(InfoContainer);
