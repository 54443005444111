import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Settings from "./settings";
import { compose } from "redux";
import withRouter from "../../withRouter/withRouter";
import { ThunkCretorGetSettings, ThunkCretorSaveSettings } from "../../../context/reducers/settingsReducer";
import PrivateRoute from "../private/privateRoute";


function SettingsContainer(props) {
    let saveFormData = function (formData) {
        props.save(formData);
    }
    if (!props.settings)
        props.get();
    if (props.settings)
        return <Settings initialValues={props.settings} onSubmit={saveFormData} />
}


const mapStateToProps = (state, ownProps) => {
    return {
        settings: state.settings.data
    }
}

const mapDispatchToProps = (dispatch) => ({
    get: () => {
        dispatch(ThunkCretorGetSettings());
    },
    save: (data) => {
        dispatch(ThunkCretorSaveSettings(data));
    }
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    PrivateRoute
)(SettingsContainer);
