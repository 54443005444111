import React, { useState, useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import styles from "./settings.component.less";
import commonStyles from "../../shared/common.component.less";
import Caption from "../../caption/caption";
import Button from "../../button/button";

export default function Settings(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return <SettingsRxForm initialValues={props.initialValues} onSubmit={props.onSubmit} />
}

let SettingsRxForm = reduxForm({ form: 'settings' })(Form);

function Form(props) {
    return (
        <div className={styles.component}>
            <form onSubmit={props.handleSubmit}>
                <Caption title="Настройки" />

                <div className={commonStyles.formLine}>
                    <label>
                        Принимать заказы <Field component={"input"} name={"allowOrders"} type={"checkbox"} />
                    </label>
                </div>
                <div className={commonStyles.formLine}>
                    <label>
                        Промокоды <Field component={"input"} name={"promoCode"} type={"checkbox"} />
                    </label>
                </div>
                <div className={commonStyles.formLine}>
                    <label>
                        Онлайн платежи <Field component={"input"} name={"onlinePayment"} type={"checkbox"} />
                    </label>
                </div>

                <Button title="сохранить" bgColor="red" mode="full" />
            </form>
        </div>
    )
}