import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "./header";
import { useNavigate } from 'react-router-dom';
import { compose } from "redux";
import withRouter from "../withRouter/withRouter";
import { ThunkCretorGetBaseInfo } from "../../context/reducers/headerReducer";
import { ThunkCretorLogout } from "../../context/reducers/authReducer";

function HeaderContainer(props) {
    const navigate = useNavigate();
    let visibilityOfBackButton = props.router.location.pathname != "/" && props.router.location.pathname != "/home";
    let [logouted, setLogouted] = useState(false);
    const fnGoBack = () => navigate(-1);
    const logout = () => {
        props.logout();
        setLogouted(true);
    }

    useEffect(() => {
        if (logouted) {
            navigate('/login');
            setLogouted(false);
        }
    });

    if (!props.baseInfo) props.get();

    if (props.baseInfo && props.isAuthenticated)
        return <Header name={props.baseInfo.name} goback={fnGoBack.bind(this)} logout={logout} visibilityOfBackButton={visibilityOfBackButton} />
    else <></>;
}


const mapStateToProps = (state, ownProps) => {
    return {
        baseInfo: state.header.data,
        isAuthenticated: state.auth.isAuthenticated
    }
}
const mapDispatchToProps = (dispatch) => ({
    get: () => {
        dispatch(ThunkCretorGetBaseInfo());
    },
    logout: () => {
        dispatch(ThunkCretorLogout());
    }
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(HeaderContainer);
