import React, { useEffect } from "react";
import PageContent from "../../pageContent/pageContent";
import Order from "../../order/order";
import styles from "./orderDetails.component.less";
import ordersStyles from "./orders.component.less";
import InfoBlock from "../../infoBlock/infoBlock";

export default function Orders(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (!props.orders)
        return;
    
    let orders = props.orders.map(x => <Order key={x.id} data={x} />);

    let isActive = (statusId) => statusId == props.activeStatusId ? " "+ordersStyles.active : "";
    return (
        <PageContent>
            <div id={ordersStyles.pageOrder}>
                <div className={ordersStyles.orderStatuses}>
                    <div>
                        <div onClick={() => props.showOrdersByStatus(1)} className={ordersStyles.orderStatusItem + " " + ordersStyles.waiting + isActive(1)} data-status="1">в ожидании</div>
                        <div onClick={() => props.showOrdersByStatus(-1)} className={ordersStyles.orderStatusItem + " " + ordersStyles.all + isActive(-1)} data-status="-1">все</div>
                        <div onClick={() => props.showOrdersByStatus(4)} className={ordersStyles.orderStatusItem + " " + ordersStyles.processing + isActive(4)} data-status="4">обрабатывается</div>
                        <div onClick={() => props.showOrdersByStatus(2)} className={ordersStyles.orderStatusItem + " " + ordersStyles.completed + isActive(2)} data-status="2">завершенные</div>
                        <div onClick={() => props.showOrdersByStatus(0)} className={ordersStyles.orderStatusItem + " " + ordersStyles.canceled + isActive(0)} data-status="0">отмененные</div>
                    </div>
                </div>
            </div>
            {orders.length ? orders :
                <div className={styles.noData}>
                    <div>данные отсутствуют <br />:((
                    </div>
                </div>    
            }
        </PageContent >
    );
}


export function OrderDetails(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let order = props.order;
    let orderDate = new Date(order.orderDate);
    let deliveryDate = new Date(order.deliveryDate);

    return (
        <div>
            <div className={props.statusPopup ? styles.bluredBlock : null}>
                <h3 className={styles.caption}>Общая информация</h3>
                <input type="hidden" id="order-id" value="" />
                <InfoBlock icon="icon-hash" title={"Номер заказа"} value={order.orderNumber} />
                <InfoBlock icon="icon-address-card-o" title={"Имя покупателя"} value={order.fullName} />
                <InfoBlock icon="icon-calendar" title={"Дата заказа"} >
                    <b>{orderDate.toLocaleTimeString('ru').slice(0, 5)}</b> {orderDate.toLocaleDateString('ru')}
                </InfoBlock>
                <InfoBlock icon="icon-calendar" title={"Дата выполнение заказа"} >
                    {order.deliveryDate == null ? "ближайшее" : (<b>{deliveryDate.toLocaleTimeString('ru').slice(0, 5)}</b> + deliveryDate.toLocaleDateString('ru'))}
                </InfoBlock>
                <InfoBlock icon="icon-home" title={"Адрес"} value={order.shippingAddress} />
                <InfoBlock icon="icon-map-signs" title={"Филиал"} value={order.storeBranchAddress} />
                <InfoBlock icon="icon-phone" title={"Номер телефона"}>
                    <a href="tel:+{order.phonenumber}">+{order.phonenumber}</a>
                </InfoBlock>

                <InfoBlock icon="icon-sort-alphabet-outline" title={"Коментарии"} value={order.comments} />
                <InfoBlock icon="icon-book" title={"Доп дата"} value={order.extraData} />
                <InfoBlock icon="icon-truck" title={"Доставка"}>
                    {order.deliveryType == 2 ? "самовывоз" : `Курьер: ${order.deliveryPrice} руб`}
                </InfoBlock>
                <InfoBlock icon="icon-basket-1" title={"Статус"} >
                    {order.statusId == 0 ? "Отменен" : (order.statusId == 1 ? "В ожидании" : "Завершен")}
                </InfoBlock>
                <InfoBlock icon="icon-wallet" title={"Метод оплаты"} >
                    {order.paymentTypeId == 0 ? "Наличные" : (order.paymentTypeId == 1 ? `Кредитная карта`
                        : `Онлайн оплата <a href="${order.cloudPaymentLink}" target="_blank">Транзакция № ${order.transactionId}</a>`)}
                </InfoBlock>
                <InfoBlock icon="icon-money" title={"Сумма товаров"}>
                    {order.summa} руб
                </InfoBlock>

                <InfoBlock icon="icon-money" title={"Сумма со скидкой"} >
                    {order.totalAmount} руб
                </InfoBlock>
                <InfoBlock icon="icon-money" title={"Итоговая сумма к оплате"}>
                    {order.summaForPay} руб
                </InfoBlock>

                <h3 className={styles.caption}>Корзина</h3>

                <div id={styles.details}></div>

                <div className={styles.summary}>
                    <h4>Итого</h4>
                    <p>Сумма товаров: <span id="summary-sum"> {order.summa}  руб</span></p>
                    <p>Доставка: <span id="summary-delivery-type">{order.deliveryType == 2 ? "самовывоз" : `Курьер: ${order.deliveryPrice} руб`}</span></p>
                    <p>Итоговая сумма к оплате: <span id="summary-sum4pay"> {order.summaForPay} руб</span></p>
                </div>

                <button className={styles.button} onClick={props.showPopup}>
                    изменить статус заказа
                </button>
            </div>
            {props.statusPopup &&
                <div id={styles.statusPopup} onClick={props.closePopup}>
                    <button className={styles.button + " " + styles.completed} onClick={(e) => props.changeOrderStatus(e, order.id, 2)}>
                        завершить
                    </button>
                    <button className={styles.button + " " + styles.processing} onClick={(e) => props.changeOrderStatus(e, order.id, 4)}>
                        обрабатывается
                    </button>
                    <button className={styles.button + " " + styles.canceled} onClick={(e) => props.changeOrderStatus(e, order.id, 0)}>
                        отменить
                    </button>
                </div>
            }

        </div>
    );
}

