import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Users from "./users";
import { compose } from "redux";
import withRouter from "../../withRouter/withRouter";
import { ThunkCretorGetUsers } from "../../../context/reducers/usersReducer";
import PrivateRoute from "../private/privateRoute";


function UsersContainer(props) {
    if (!props.users)
        props.get();

    return <Users users={props.users} />
}


const mapStateToProps = (state, ownProps) => {
    return {
        users: state.users.data
    }
}

const mapDispatchToProps = (dispatch) => ({
    get: () => dispatch(ThunkCretorGetUsers())
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    PrivateRoute
)(UsersContainer);