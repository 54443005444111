import api from "../../api/infoApi";
import { toast } from 'react-toastify';

const ACTION_SET_INFO = "store/info/set";

let initialState = {
    data: null
}


export default function InfoReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_SET_INFO: {
            return {
                ...state,
                data: action.data
            }
        }
        default: return state;
    }
}

export const ActionSaveInfo = (data) => ({
    type: ACTION_SET_INFO,
    data: data
});

export function ThunkCretorGetInfo() {
    return (dispatch) => {
        api.get().then((response) => {
            if (response.status == 200) {
                let operResult = response.data;
                dispatch(ActionSaveInfo(operResult.value));
            }
        });
    }
}

export function ThunkCretorSaveInfo(postData) {
    return (dispatch) => {
        api.post(postData).then((response) => {
            if (response.status == 200) {
                let operResult = response.data;
                dispatch(ActionSaveInfo(operResult.value));
                toast.success("Успешно сохранено!");
            }
        });
    }
}