import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ProductDetails from "./productDetails";
import { compose } from "redux";
import withRouter from "../../withRouter/withRouter";
import { ThunkCretorGetProductDetails, ThunkCretorSaveProduct } from "../../../context/reducers/productDetailsReducer";
import PrivateRoute from "../private/privateRoute";


function ProductDetailsContainer(props) {
    let id = props.router.params.id;

    let saveFormData = (formData) => {
        props.save({
            id: formData.id,
            catId: formData.catId,
            name: formData.name,
            price: +formData.price,
            isAvailable: formData.isAvailable,
            discount: formData.discount,
        });
    }

    if (id) {
        if (!props.productDetails || props.productDetails.id!=id) props.get(props.router.params.id);
        if (props.productDetails && props.productDetails.id == id)
            return <ProductDetails initialValues={props.productDetails} onSubmit={saveFormData} />
    }
}

const mapStateToProps = (state) => {
    return {
        productDetails: state.productDetails.data,
    }
}

const mapDispatchToProps = (dispatch) => ({
    get: (prodId) => {
        dispatch(ThunkCretorGetProductDetails(prodId));
    },
    save: (model) => {
        dispatch(ThunkCretorSaveProduct(model));
    }
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    PrivateRoute
)(ProductDetailsContainer);