import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Login from "./login";
import { compose } from "redux";
import withRouter from "../../withRouter/withRouter";
import { useNavigate } from "react-router-dom";
import { ThunkCretorLogin } from "../../../context/reducers/authReducer";

function LoginContainer(props) {
    let [showPass, setShowPass] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (props.isAuthenticated)
            navigate('/home');
    });

    let showHidePassword = (e) => {
        setShowPass(!showPass);
    };

    //if (props.login)
    //    redicrect();
    
    return props.isAuthenticated == false ? <Login onSubmit={props.login} showHidePassword={showHidePassword} showPass={showPass} /> : <></>;
}

const mapStateToProps = (state, ownProps) => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    }
}

const mapDispatchToProps = (dispatch) => ({
    login: (model) => {
        dispatch(ThunkCretorLogin({ Login: model.login, Password: model.password }));
    }
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(LoginContainer);
