import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./header.component.less";

const Header = (props) => {
    return (
        <div className={styles.component}>
            <div className={styles.logoOms}>
                <div></div>
                <p>
                    Система управления заказами
                </p>
            </div>

            <div className={styles.bottomSection}>

                {
                    props.visibilityOfBackButton &&
                    <div onClick={props.goback} className={styles.goBack}>
                        <i className="icon-left-open" /> назад
                    </div>
                }
                

                <span>
                    { props.name}
                </span>
                <div className={styles.logout} onClick={props.logout}>
                    <i className="icon-logout" />
                </div>
            </div>
        </div>
    );
}


export default Header;