import apiConf from "./apiConfiguration";
import net from "./net";

const settingsApi = {
    get() {
        return net.get(apiConf.endpoint("/info/getstore")).then((response) => response);
    },
    post({ about }) {
        return net.post(apiConf.endpoint("/info/saveinfo"), {
            about: about
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => response);
    },
};

export default settingsApi;