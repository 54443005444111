import React, { useState } from "react";
import { connect } from "react-redux";
import OrderPage from "./orders";
import { compose } from "redux";
import withRouter from "../../withRouter/withRouter";
import { ThunkCretorGetOrders } from "../../../context/reducers/ordersReducer";
import PrivateRoute from "../private/privateRoute";


function OrdersContainer(props) {
    let [currentlyOnDisplay, setValue] = useState(null)
    let [currentlyActiveStatus, setStatusIdValue] = useState(-1)

    if (props.orders.length == 0)
        props.getOrders();


    let showOrders = function (statusId) {
        setStatusIdValue(statusId);
        if (statusId == -1) {
            setValue(props.orders.sort((a, b) => b.orderNumber - a.orderNumber));
        }
        else {
            let ordersWainting = props.orders.filter(x => {
                if (x.statusId == statusId)
                    return x;
            });
            setValue(ordersWainting.sort((a, b) => b.orderNumber - a.orderNumber))
        }
    };
    if (props.orders.length && currentlyOnDisplay == null) showOrders(1)
    return <OrderPage orders={currentlyOnDisplay} showOrdersByStatus={(statusId) => showOrders(statusId)} activeStatusId={currentlyActiveStatus} />
}


const mapStateToProps = (state, ownProps) => {
    return {
        orders: state.orders.data
    }
}

const mapDispatchToProps = (dispatch) => ({
    getOrders: () => {
        dispatch(ThunkCretorGetOrders())
    }
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    PrivateRoute
)(OrdersContainer);
