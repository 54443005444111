import MenuItem from "../../menuItem/menuItem";
import PrivateRoute from "../private/privateRoute";

function Home() {
    return (
        <>
            <nav>
                <MenuItem name="Заказы" iconName="money" uri="/orders" />
                <MenuItem name="Настройки" iconName="cog-alt" uri="/settings" />
                <MenuItem name="Статистика" iconName="signal-1" uri="/stats" />
                <MenuItem name="Информация" iconName="info" uri="/info" />
                <MenuItem name="Продукты" iconName="book" uri="/categories" />
                <MenuItem name="Пользователи" iconName="users" uri="/users" />
            </nav>
        </>
    )
}

export default PrivateRoute(Home);