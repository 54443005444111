import styles from "./clipboard.component.less";
import { toast } from 'react-toastify';

export default function Clipboard(props) {
    let copyToClipboard = () => {
        navigator.clipboard.writeText(props.children.toString().replace(',', ''));
        toast.success("Номер телефона успешно скопирован!");
    }
    return (
        <span className={styles.component} onClick={() => copyToClipboard()}>
            {props.children}
        </span>
    )
}