import styles from "./productDetails.component.less";
import { reduxForm, Field } from "redux-form";
import PageContent from "../../pageContent/pageContent";
import Button from "../../button/button";

export default function ProductDetails(props) {
    let details = props.details;
    console.log(props)
    return (
        <PageContent>
            <ProductDetailsRxForm initialValues={props.initialValues} onSubmit={props.onSubmit} />
        </PageContent>
    )
}


let ProductDetailsRxForm = reduxForm({ form: 'productDetails' })(Form);

function Form(props) {
    return (
        <div className={styles.component}>
            <form onSubmit={props.handleSubmit}>
                <Field component={"input"} name={"id"} type={"hidden"} />
                <Field component={"input"} name={"catId"} type={"hidden"} />
                <div className={styles.line}>
                    <b>Название:</b> <Field className={styles.input} component={"input"} name={"name"} type={"text"} />
                </div>
                <div className={styles.line}>
                    <b>Цена:</b> <Field className={styles.input} component={"input"} name={"price"} type={"number"} /> руб
                </div>
                <div className={styles.line}>
                    <b>Скидка:</b> <Field className={styles.input} component={"input"} name={"discount"} type={"number"} />%
                </div>
                <div className={styles.line}>
                    <b>Доступность:</b> <Field component={"input"} name={"isAvailable"} type={"checkbox"} />
                </div>
                <Button title="сохранить" bgColor="red" mode="full" />
            </form>
        </div>
    )
}