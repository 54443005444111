import apiConf from "./apiConfiguration";
import net from "./net";

const ordersApi = {
    get() {
        return net.get(apiConf.endpoint("/orders")).then((response) => response);
    },
    changeOrderStatus(orderId, newStatusId) {
        return net.post(apiConf.endpoint("/orders/changestatus"), {
            orderId: orderId,
            status: newStatusId
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then((response) => response);
    },
    check4NewOrders(latestOrderData) {
        return net.get(apiConf.endpoint("/orders/getneworders/" + latestOrderData)).then((response) => response);
    },
};

export default ordersApi;