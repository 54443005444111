import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import isAuthenticated from '../../../services/authService';
import Login from '../login/login.container';
import Header from "../../header/header.container";
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function PrivateRoute(Component) {
    
    let NewPrivateComponent = function (props) {
        const navigate = useNavigate();
        let authState = useSelector(state => {
            return state.auth;
        });

        useEffect(() => {
            if (!authState.isAuthenticated)
                navigate('/login', { replace: true });
        });
       
        return authState.isAuthenticated  ?<>
            <Component {...props} />
        </> : <></>;
    }
    return NewPrivateComponent;
}