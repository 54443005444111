import api from "../../api/settingsApi";
import { toast } from 'react-toastify';

const ACTION_SET_SETTINGS = "store/settings/set";

let initialState = {
    data: null
}


export default function SettingsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_SET_SETTINGS: {
            return {
                ...state,
                data: action.data
            }
        }
        default: return state;
    }
}

export const ActionSaveSettings = (data) => ({
    type: ACTION_SET_SETTINGS,
    data: data
});

export function ThunkCretorGetSettings() {
    return (dispatch) => {
        api.get().then((response) => {
            if (response.status == 200) {
                let operResult = response.data;
                dispatch(ActionSaveSettings(operResult.value));
            }
        });
    }
}

export function ThunkCretorSaveSettings(postData) {
    return (dispatch) => {
        api.post(postData).then((response) => {
            if (response.status == 200) {
                let operResult = response.data;
                dispatch(ActionSaveSettings(operResult.value));
                toast.success("Успешно сохранено!");
            }
        });
    }
}