import styles from "./infoBlock.component.less"

export default function InfoBlock(props) {
    return (
        <div className={styles.component}>
            <div>
                <i className={props.icon}></i>
            </div>
            <div>
                <h4>{props.title}</h4>
                <p>{props.children ? props.children : props.value}</p>
            </div>
        </div>
    )
}