import api from "../../api/authApi";
import { toast } from 'react-toastify';

const ACTION_SET = "store/auth/set";

let initialState = {
    isAuthenticated: null
}


export default function AuthReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_SET: {
            return {
                ...state,
                isAuthenticated: action.data
            }
        }
        default: return state;
    }
}

const ActionSaveAuth = (data) => ({
    type: ACTION_SET,
    data: data
});

export function ThunkCretorGetAuthenticationStatus() {
    return (dispatch) => {
        api.get().then((response) => {
            if (response.status == 200) {
                dispatch(ActionSaveAuth(response.data));
            }
        });
    }
}

export function ThunkCretorLogin(model) {
    return (dispatch) => {
        api.login(model).then((response) => {
            if (response.status == 200) {
                dispatch(ActionSaveAuth(response.data.successful));
            }
        });
    }
}

export function ThunkCretorLogout(model) {
    return (dispatch) => {
        api.logout().then((response) => {
            if (response.status == 200) {
                dispatch(ActionSaveAuth(!response.data.successful));
            }
        });
    }
}