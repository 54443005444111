import styles from "./order.component.less";
import { NavLink } from "react-router-dom";

export default function Order(props) {
    let order = props.data;
    let orderDate = new Date(props.data.orderDate);
    return (    
        <NavLink to={"/orders/" + order.id} className={styles.component}>
            <p>
                Покупател: {order.fullName}
            </p>
            <p>
                Время: <b>[{orderDate.toLocaleTimeString('ru').slice(0, 5)}]</b> {orderDate.toLocaleDateString('ru')}
            </p>
            <hr />
            <div>
                <div><b>#{order.orderNumber}</b> Статус: <span className={GetStatusClassName(+order.statusId)}>{GetStatusName(+order.statusId)}</span> </div>
                <div>{order.summaForPay} 0 Руб</div>
            </div>
        </NavLink>
    )
}


function GetStatusClassName(statusId) {
    switch (statusId) {
        case 0: return styles.canceled;
        case 1: return styles.waiting;
        case 2: return styles.completed;
        case 4: return styles.processing;
        default: return '';
    }
}

function GetStatusName(statusId) {
    switch (statusId) {
        case 0: return 'отменен';
        case 1: return 'в ожидании';
        case 2: return 'завершенные';
        case 4: return 'обрабатывается';
        default: return '';
    }
}
