import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Stats from "./stats";
import { compose } from "redux";
import withRouter from "../../withRouter/withRouter";
import { ThunkCretorGetStats } from "../../../context/reducers/statsReducer";
import PrivateRoute from "../private/privateRoute";


function StatsContainer(props) {
    if (!props.stats) props.get();
    else return <Stats {...props.stats} />
}

const mapStateToProps = (state, ownProps) => {
    return {
        stats: state.stats.data
    }
}

const mapDispatchToProps = (dispatch) => ({
    get: () => {
        dispatch(ThunkCretorGetStats());
    }
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    PrivateRoute
)(StatsContainer);
