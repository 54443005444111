import styles from "./info.component.less";
import Caption from "../../caption/caption";
import PageContent from "../../pageContent/pageContent";

import Button from "../../button/button";
import { reduxForm, Field } from "redux-form";
import commonStyles from "../../shared/common.component.less";

let InfoRxForm = reduxForm({ form: "info" })(Form);

function Form(props) {
    return (
        <form onSubmit={props.handleSubmit}>
            <Field className={commonStyles.textarea} name={"about"} component={"textarea"} />
            <Button title="сохранить" bgColor="red" mode="full" />
        </form>
    )
}

export default function Info(props) {
    return (
        <PageContent>
            <Caption title="Информация" />
            <InfoRxForm initialValues={props} onSubmit={props.onSubmit} />
        </PageContent>
    )
}