import { useState, useEffect } from "react";
import styles from "./app.component.less";
import Home from "../../pages/home/home";
import Orders from "../../pages/orders/orders.container";
import OrderDetails from "../../pages/orders/order-details.container";

import Stats from "../../pages/stats/stats.container";
import Categories from "../../pages/products/categories.container";
import Products from "../../pages/products/products.container";
import ProductDetails from "../../pages/products/productDetails.container";
import Settings from "../../pages/settings/settings.container";
import Info from "../../pages/info/info.container";
import Login from "../../pages/login/login.container";
import Users from "../../pages/users/users.container";
import { ToastContainer } from 'react-toastify';
import { Route, Routes , Navigate } from "react-router-dom";
import PrivateRoute from "../private/privateRoute";
import Header from "../../header/header.container";

function App(props) {

    useEffect(() => {
        const interval = setInterval(() => props.check4NewOrders(props.lastOrderDate), 5000);
        return () => clearInterval(interval)
    }, [props.lastOrderDate]);

    return (
        <div className={styles.App}>
            <Header />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/home" element={<Home />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/orders/:id" element={<OrderDetails />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/categories/:id" element={<Products />} />
                <Route path="/products/:id" element={<ProductDetails />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/info" element={<Info />} />
                <Route path="/users" element={<Users />} />
                <Route path="/stats" element={<Stats />} />
            </Routes>
            <ToastContainer
                position="bottom-center"
                theme="colored"
                autoClose={1500} />
        </div>
    );
}

export default App;
