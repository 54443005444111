import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import App from "./app";
import { compose } from "redux";
import withRouter from "../../withRouter/withRouter";
import { ThunkCretorGetAuthenticationStatus } from "../../../context/reducers/authReducer";
import { ThunkCretorCheck4NewOrders } from "../../../context/reducers/ordersReducer";


function AppContainer(props) {
    if (!props.isAuthenticated)
        props.checkIsUserAuthenticated();

    let check4NewOrders = (lastOrderDate) => {
        if (lastOrderDate)
            props.check4NewOrders(lastOrderDate);
    };
    return <App check4NewOrders={check4NewOrders} lastOrderDate={props.lastOrderDate} />
}

const mapStateToProps = (state, ownProps) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        lastOrderDate: state.orders.extraData.lastOrderDate
    }
}


const mapDispatchToProps = (dispatch) => ({
    checkIsUserAuthenticated: () => {
        dispatch(ThunkCretorGetAuthenticationStatus());
    },
    check4NewOrders: (lastOrderDate) => {
        dispatch(ThunkCretorCheck4NewOrders(lastOrderDate));
    }
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AppContainer);
